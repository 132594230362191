"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TabCardContainer = exports.TabContainer = exports.TabWrapper = exports.CardMenu = void 0;
const material_1 = require("@mui/material");
const styles_1 = require("@mui/material/styles");
exports.CardMenu = (0, styles_1.styled)(material_1.Card)(({ theme, show }) => ({
    height: 'calc(100vh - 79px)',
    zIndex: 999,
    boxShadow: '0px 1px 5px 0px #00000040',
    [theme.breakpoints.down('md')]: {
        display: show == 'true' ? '' : 'none',
        position: 'absolute',
        height: 'calc(100% - 90px)',
    },
    '>div': {
        padding: 0,
    },
}));
exports.TabWrapper = (0, styles_1.styled)(material_1.Tabs)(() => ({
    '& .css-3r82j0-MuiTabs-flexContainer': {
        alignItems: 'start',
    },
    '& .MuiTabs-indicator': {
        display: 'none',
    },
}));
exports.TabContainer = (0, styles_1.styled)(material_1.Box)(() => ({
    margin: '0px 3px',
    height: '100%',
}));
exports.TabCardContainer = (0, styles_1.styled)(material_1.Card)(() => ({
    boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
    height: 'calc(100vh - 79px)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));
