"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.setActiveCallExtraInfo = exports.setActiveCall = exports.callsSlice = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const initialState = {
    activeCall: undefined,
    activeCallExtraInfo: undefined,
};
exports.callsSlice = (0, toolkit_1.createSlice)({
    name: 'calls',
    initialState,
    reducers: {
        setActiveCall: (state, action) => {
            state.activeCall = action.payload;
        },
        setActiveCallExtraInfo: (state, action) => {
            var _a, _b, _c, _d, _e, _f, _g;
            if (state.activeCall &&
                state.activeCall.callInfo &&
                state.activeCall.callInfo.sessionId &&
                action.payload &&
                ((_a = action.payload) === null || _a === void 0 ? void 0 : _a.callInfo) &&
                ((_c = (_b = action.payload) === null || _b === void 0 ? void 0 : _b.callInfo) === null || _c === void 0 ? void 0 : _c.sessionId) &&
                ((_e = (_d = action.payload) === null || _d === void 0 ? void 0 : _d.callInfo) === null || _e === void 0 ? void 0 : _e.sessionId) == ((_g = (_f = state === null || state === void 0 ? void 0 : state.activeCall) === null || _f === void 0 ? void 0 : _f.callInfo) === null || _g === void 0 ? void 0 : _g.sessionId)) {
                state.activeCallExtraInfo = action.payload;
            }
        },
    },
});
_a = exports.callsSlice.actions, exports.setActiveCall = _a.setActiveCall, exports.setActiveCallExtraInfo = _a.setActiveCallExtraInfo;
exports.default = exports.callsSlice.reducer;
