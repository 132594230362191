"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.validateEmail = exports.cleanNumber = exports.formatPhoneNumber = void 0;
const formatPhoneNumber = (rawnumber) => {
    let number = (0, exports.cleanNumber)(rawnumber);
    let newNumber = number;
    if (number) {
        if (number.length === 11) {
            if (number[0] === '1') {
                number = number.substring(1);
            }
        }
        if (number.length === 10) {
            newNumber = `(${number.substring(0, 3)}) ${number.substring(3, 6)}-${number.substring(6)}`;
        }
    }
    return newNumber;
};
exports.formatPhoneNumber = formatPhoneNumber;
const cleanNumber = (number) => {
    if (number) {
        return number.replace('+', '');
    }
    return number;
};
exports.cleanNumber = cleanNumber;
const validateEmail = (email) => {
    if (email.includes('@')) {
        const mainParts = email.split('@');
        if (mainParts.length === 2) {
            return mainParts[1].includes('.');
        }
    }
    return false;
};
exports.validateEmail = validateEmail;
