"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const material_1 = require("@mui/material");
const AppointmentItem_1 = require("./AppointmentItem");
const processPattern = (pattern) => {
    let currentChar = pattern[0];
    const parts = [];
    let currentLength = 1;
    for (let i = 1; i < pattern.length; i++) {
        if (pattern[i] === currentChar) {
            currentLength++;
        }
        else {
            parts.push({
                show: currentChar.toLowerCase() === 'x',
                length: currentLength,
            });
            currentChar = pattern[i];
            currentLength = 1;
        }
    }
    parts.push({
        show: currentChar.toLowerCase() === 'x',
        length: currentLength,
    });
    return parts;
};
const AppointmentTimeBar = ({ isSelected, primaryColor, primaryTime, secondaryColor, secondaryTime }) => {
    const durationDisplay = (0, react_1.useMemo)(() => {
        if (primaryTime) {
            const duration = ((primaryTime === null || primaryTime === void 0 ? void 0 : primaryTime.length) / 2) * 10;
            const hrs = Math.floor(duration / 60);
            const min = duration % 60;
            return `${hrs > 0 ? `${hrs}h ` : ''}${min}m`;
        }
        return '';
    }, [primaryTime]);
    const firstBar = (0, react_1.useMemo)(() => {
        if (primaryTime) {
            const parts = processPattern(primaryTime);
            return ((0, jsx_runtime_1.jsx)(material_1.Box, { className: 'time-bar-container', children: parts.map((part, index) => part.show ? ((0, jsx_runtime_1.jsx)(material_1.Box, { className: 'timer-bar', sx: {
                        backgroundColor: primaryColor,
                        width: '20px',
                    } }, index)) : ((0, jsx_runtime_1.jsx)(material_1.Box, { className: 'time-bar-empty', sx: {
                        width: '15px',
                    } }, index))) }));
        }
        return null;
    }, [primaryTime, primaryColor]);
    const secondBar = (0, react_1.useMemo)(() => {
        if (secondaryTime) {
            const parts = processPattern(secondaryTime);
            return ((0, jsx_runtime_1.jsx)(material_1.Box, { className: 'time-bar-container', children: parts.map((part, index) => part.show ? ((0, jsx_runtime_1.jsx)(material_1.Box, { className: 'timer-bar', sx: {
                        backgroundColor: secondaryColor,
                        width: '20px',
                    } }, index)) : ((0, jsx_runtime_1.jsx)(material_1.Box, { className: 'time-bar-empty', sx: {
                        width: '15px',
                    } }, index))) }));
        }
        return null;
    }, [secondaryTime, secondaryColor]);
    return ((0, jsx_runtime_1.jsxs)(material_1.Box, { className: 'timeBar', children: [(0, jsx_runtime_1.jsx)(AppointmentItem_1.MainText, { isSelected: isSelected, children: durationDisplay }), (firstBar || secondBar) && (0, jsx_runtime_1.jsx)(AppointmentItem_1.MainText, { isSelected: isSelected, children: "|" }), (0, jsx_runtime_1.jsxs)(material_1.Box, { className: 'bars', children: [firstBar, secondBar] })] }));
};
exports.default = AppointmentTimeBar;
