"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.fetchActivationKey = exports.updateTenantProfile = exports.updateTenant = exports.createTenant = exports.fetchTenants = exports.updateTenantSettings = exports.fetchTenantSettings = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const axiosHelper_1 = require("@utils/axiosHelper");
const constants_1 = require("@appConstants/constants");
// import { isCurrentTenant } from '@utils/isCurrentTenant';
const initialize_instance_1 = require("@apis/initialize.instance");
exports.fetchTenantSettings = (0, toolkit_1.createAsyncThunk)('tenants/fetchTenantSettings', (_, thunkApi) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const storeState = thunkApi.getState();
        const { tenantId, token, currentUser, globalRole } = storeState.currentUserReducer;
        if (!tenantId) {
            return Promise.reject('noTenantId');
        }
        if (!token) {
            return Promise.reject('noToken');
        }
        if (!currentUser) {
            return Promise.reject('noCurrentUser');
        }
        let response;
        if (globalRole === constants_1.GLOBAL_ROLES.SITE_ADMIN) {
            response = yield initialize_instance_1.adminInstance.get(`tenants/${tenantId}`, (0, axiosHelper_1.addTokenToConfig)({}, token));
        }
        else {
            // response = isCurrentTenant() ?
            response = yield initialize_instance_1.tenantInstance.get(`tenants/${tenantId}`, (0, axiosHelper_1.addTokenToConfig)({}, token));
            // : await adminInstance.get(`tenants/${tenantId}`, addTokenToConfig({}, token));
        }
        // const response = await axios.get(`tenants/${tenantId}`, addTokenToConfig({}, token));
        return response.data;
    }
    catch (err) {
        throw err;
    }
}));
exports.updateTenantSettings = (0, toolkit_1.createAsyncThunk)('tenants/updateTenantSettings', (setting, thunkApi) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const storeState = thunkApi.getState();
        const { tenantId, token, currentUser, globalRole } = storeState.currentUserReducer;
        if (!tenantId) {
            return Promise.reject('noTenantId');
        }
        if (!token) {
            return Promise.reject('noToken');
        }
        if (!currentUser) {
            return Promise.reject('noCurrentUser');
        }
        let response;
        if (globalRole === constants_1.GLOBAL_ROLES.SITE_ADMIN) {
            response = yield initialize_instance_1.adminInstance.patch(`tenants/basic/${tenantId}`, setting, (0, axiosHelper_1.addTokenToConfig)({}, token));
        }
        else {
            response =
                // isCurrentTenant()
                //   ?
                yield initialize_instance_1.tenantInstance.patch(`tenants/basic/${tenantId}`, setting, (0, axiosHelper_1.addTokenToConfig)({}, token));
            // : await axiosAdmin.patch(`tenants/basic/${tenantId}`, setting, addTokenToConfig({}, token));
            // : await adminInstance.put(`tenants/basic/${tenantId}`, setting, addTokenToConfig({}, token));
        }
        // const response = await axiosAdmin.put(`tenants/basic/${tenantId}`, setting, addTokenToConfig({}, token));
        return response.data;
    }
    catch (err) {
        throw err;
    }
}));
exports.fetchTenants = (0, toolkit_1.createAsyncThunk)('tenants/fetchTenents', (_, thunkApi) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const storeState = thunkApi.getState();
        const { token, currentUser } = storeState.currentUserReducer;
        if (!token) {
            return Promise.reject('noToken');
        }
        if (!currentUser) {
            return Promise.reject('nocurrentUser');
        }
        const response = yield initialize_instance_1.adminInstance.get('tenants', (0, axiosHelper_1.addTokenToConfig)({}, token));
        return response.data;
    }
    catch (err) {
        throw err;
    }
}));
exports.createTenant = (0, toolkit_1.createAsyncThunk)('tenants/createTenant', (tenant, thunkApi) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const storeState = thunkApi.getState();
        const { token, currentUser } = storeState.currentUserReducer;
        if (!token) {
            return Promise.reject('noToken');
        }
        if (!currentUser) {
            return Promise.reject('nocurrentUser');
        }
        const response = yield initialize_instance_1.adminInstance.post('tenants', tenant, (0, axiosHelper_1.addTokenToConfig)({}, token));
        return response.data;
    }
    catch (err) {
        throw err;
    }
}));
exports.updateTenant = (0, toolkit_1.createAsyncThunk)('tenants/updateTenent', ({ id, tenant, userIds }, thunkApi) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const storeState = thunkApi.getState();
        const { token, currentUser } = storeState.currentUserReducer;
        if (!token) {
            return Promise.reject('noToken');
        }
        if (!currentUser) {
            return Promise.reject('nocurrentUser');
        }
        const body = {
            tenant,
            userIds,
        };
        // const response = await axiosAdmin.patch(`tenants/${id}`, body, addTokenToConfig({}, token));
        const response = yield initialize_instance_1.adminInstance.patch(`tenants/${id}`, body, (0, axiosHelper_1.addTokenToConfig)({}, token));
        if (response.status === 200 && response.data) {
            thunkApi.dispatch((0, exports.fetchTenants)());
        }
        return response.data;
    }
    catch (err) {
        throw err;
    }
}));
exports.updateTenantProfile = (0, toolkit_1.createAsyncThunk)('tenants/updateTenentProfile', ({ id, tenant }, thunkApi) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const storeState = thunkApi.getState();
        const { token, currentUser } = storeState.currentUserReducer;
        if (!token) {
            return Promise.reject('noToken');
        }
        if (!currentUser) {
            return Promise.reject('nocurrentUser');
        }
        const userIds = [];
        const body = {
            tenant,
            userIds,
        };
        const response = 
        // isCurrentTenant() ?
        yield initialize_instance_1.tenantInstance.patch(`tenants/${id}`, body, (0, axiosHelper_1.addTokenToConfig)({}, token));
        // : await adminInstance.put(`tenants/${id}`, body, addTokenToConfig({}, token));
        return response.data;
    }
    catch (err) {
        throw err;
    }
}));
exports.fetchActivationKey = (0, toolkit_1.createAsyncThunk)('tenants/fetchActivationKey', ({ tenantId }, thunkApi) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const storeState = thunkApi.getState();
        const { token, currentUser } = storeState.currentUserReducer;
        if (!token) {
            return Promise.reject('noToken');
        }
        if (!currentUser) {
            return Promise.reject('noCurrentUser');
        }
        // const response = await axiosAdmin.post(`tenants/new-activation-key/${tenantId}`, {}, addTokenToConfig({}, token));
        const response = yield initialize_instance_1.adminInstance.post(`tenants/new-activation-key/${tenantId}`, {}, (0, axiosHelper_1.addTokenToConfig)({}, token));
        return response.data;
    }
    catch (err) {
        throw err;
    }
}));
