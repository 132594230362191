"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const material_1 = require("@mui/material");
const icons_material_1 = require("@mui/icons-material");
const react_redux_1 = require("react-redux");
const constants_1 = require("@appConstants/constants");
const typography_1 = require("@styles/typography");
const kioskSlice_1 = require("@redux/reducers/kioskSlice");
require("./settings.scss");
const UserSettingModal = ({ isOpen, handleClose }) => {
    const dispatch = (0, react_redux_1.useDispatch)();
    const { currentApiVersion } = (0, react_redux_1.useSelector)((state) => state.currentUserReducer);
    const [copied, setCopied] = (0, react_1.useState)(false);
    const handleKiosk = () => {
        dispatch((0, kioskSlice_1.setIsKiosk)(true));
        handleClose();
    };
    const handleCopyText = () => {
        var _a, _b;
        let text = (_a = document.getElementById('mytext')) === null || _a === void 0 ? void 0 : _a.textContent;
        if (text && ((_b = navigator === null || navigator === void 0 ? void 0 : navigator.clipboard) === null || _b === void 0 ? void 0 : _b.writeText)) {
            text = text.replace('API', '  API');
            setCopied(true);
            navigator.clipboard.writeText(text);
            setTimeout(() => {
                setCopied(false);
            }, constants_1.THREE_SECOND);
        }
    };
    const content = (0, react_1.useMemo)(() => {
        return ((0, jsx_runtime_1.jsxs)(material_1.Box, { className: 'versions', id: 'mytext', children: [(0, jsx_runtime_1.jsxs)(material_1.Box, { className: 'version', children: [(0, jsx_runtime_1.jsx)(typography_1.MainTypography, { variant: 'h6', children: "WEB APP VERSION: " }), (0, jsx_runtime_1.jsx)(typography_1.MainTypography, { children: constants_1.WEBAPP_VERSION !== null && constants_1.WEBAPP_VERSION !== void 0 ? constants_1.WEBAPP_VERSION : 'N/A' })] }), (0, jsx_runtime_1.jsxs)(material_1.Box, { className: 'version', children: [(0, jsx_runtime_1.jsx)(typography_1.MainTypography, { variant: 'h6', children: "API VERSION: " }), (0, jsx_runtime_1.jsx)(typography_1.MainTypography, { children: currentApiVersion !== null && currentApiVersion !== void 0 ? currentApiVersion : 'N/A' })] })] }));
    }, [constants_1.WEBAPP_VERSION, currentApiVersion]);
    return ((0, jsx_runtime_1.jsxs)(material_1.Dialog, { className: 'settingsModal', open: isOpen, onClose: handleClose, maxWidth: 'lg', children: [(0, jsx_runtime_1.jsx)(material_1.DialogTitle, { children: "Tenant Settings" }), (0, jsx_runtime_1.jsx)(material_1.DialogContent, { className: 'settingsContent', children: (0, jsx_runtime_1.jsxs)(material_1.Box, { className: 'content', children: [content, (0, jsx_runtime_1.jsx)(material_1.Button, { style: { float: 'right' }, onClick: handleCopyText, children: copied ? (0, jsx_runtime_1.jsx)(icons_material_1.CheckCircleOutline, { style: { color: 'green' } }) : (0, jsx_runtime_1.jsx)(icons_material_1.FileCopy, { color: 'primary' }) })] }) }), (0, jsx_runtime_1.jsxs)(material_1.DialogActions, { className: 'actions', children: [(0, jsx_runtime_1.jsx)(material_1.Button, { variant: 'contained', color: 'primary', onClick: handleKiosk, children: "Make Kiosk" }), (0, jsx_runtime_1.jsx)(material_1.Button, { variant: 'outlined', color: 'secondary', onClick: handleClose, children: "Close" })] })] }));
};
exports.default = UserSettingModal;
