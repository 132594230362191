"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.retentionMessageSlice = void 0;
const react_toastify_1 = require("react-toastify");
const toolkit_1 = require("@reduxjs/toolkit");
const retentionMessage_1 = require("@services/retentionMessage");
const constants_1 = require("@appConstants/constants");
const initialState = {
    retentionMessages: [],
    retentionMessagesStatus: constants_1.REQUEST_STATUS.PENDING,
};
exports.retentionMessageSlice = (0, toolkit_1.createSlice)({
    name: 'retentionMessage',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(retentionMessage_1.createRetentionMessage.pending, (state) => {
            state.retentionMessagesStatus = constants_1.REQUEST_STATUS.PENDING;
        })
            .addCase(retentionMessage_1.createRetentionMessage.fulfilled, (state, action) => {
            if (action.payload) {
                state.retentionMessagesStatus = constants_1.REQUEST_STATUS.SUCCEEDED;
                (0, react_toastify_1.toast)('Create Retention Message Successfully', {
                    type: 'success',
                    autoClose: 5000,
                });
            }
            else {
                state.retentionMessagesStatus = constants_1.REQUEST_STATUS.FAILED;
                (0, react_toastify_1.toast)('Error Creating Retention Messages', {
                    type: 'error',
                    autoClose: 5000,
                });
            }
        })
            .addCase(retentionMessage_1.createRetentionMessage.rejected, (state, action) => {
            state.retentionMessagesStatus = constants_1.REQUEST_STATUS.FAILED;
            (0, react_toastify_1.toast)('Error Creating Retention Messages', {
                type: 'error',
                autoClose: 5000,
            });
        });
        builder
            .addCase(retentionMessage_1.fetchRetentionMessage.pending, (state) => {
            state.retentionMessagesStatus = constants_1.REQUEST_STATUS.PENDING;
        })
            .addCase(retentionMessage_1.fetchRetentionMessage.fulfilled, (state, action) => {
            if (action.payload && Array.isArray(action.payload)) {
                state.retentionMessagesStatus = constants_1.REQUEST_STATUS.SUCCEEDED;
                state.retentionMessages = [...action.payload];
            }
            else {
                state.retentionMessagesStatus = constants_1.REQUEST_STATUS.FAILED;
                (0, react_toastify_1.toast)('Error Fetching Retention Messages', {
                    type: 'error',
                    autoClose: 5000,
                });
            }
        })
            .addCase(retentionMessage_1.fetchRetentionMessage.rejected, (state, action) => {
            state.retentionMessagesStatus = constants_1.REQUEST_STATUS.FAILED;
            (0, react_toastify_1.toast)('Error Fetching Retention Messages', {
                type: 'error',
                autoClose: 5000,
            });
        });
        builder
            .addCase(retentionMessage_1.updateRetentionMessage.pending, (state) => {
            state.retentionMessagesStatus = constants_1.REQUEST_STATUS.PENDING;
        })
            .addCase(retentionMessage_1.updateRetentionMessage.fulfilled, (state, action) => {
            if (action.payload) {
                state.retentionMessagesStatus = constants_1.REQUEST_STATUS.SUCCEEDED;
                (0, react_toastify_1.toast)('Retention Message updated successfully', {
                    type: 'success',
                    autoClose: 5000,
                });
            }
            else {
                state.retentionMessagesStatus = constants_1.REQUEST_STATUS.FAILED;
                (0, react_toastify_1.toast)('Retention Message Failed to Update', {
                    type: 'error',
                    autoClose: 5000,
                });
            }
        })
            .addCase(retentionMessage_1.updateRetentionMessage.rejected, (state) => {
            state.retentionMessagesStatus = constants_1.REQUEST_STATUS.FAILED;
            (0, react_toastify_1.toast)('Retention Message Failed to Updated', {
                type: 'error',
                autoClose: 5000,
            });
        });
        builder
            .addCase(retentionMessage_1.deleteRetentionMessage.pending, (state) => {
            state.retentionMessagesStatus = constants_1.REQUEST_STATUS.PENDING;
        })
            .addCase(retentionMessage_1.deleteRetentionMessage.fulfilled, (state, action) => {
            if (action.payload) {
                state.retentionMessagesStatus = constants_1.REQUEST_STATUS.SUCCEEDED;
                (0, react_toastify_1.toast)('Retention Message Deleted successfully', {
                    type: 'success',
                    autoClose: 5000,
                });
            }
            else {
                state.retentionMessagesStatus = constants_1.REQUEST_STATUS.FAILED;
                (0, react_toastify_1.toast)('Retention Message Failed to Deleted', {
                    type: 'error',
                    autoClose: 5000,
                });
            }
        })
            .addCase(retentionMessage_1.deleteRetentionMessage.rejected, (state) => {
            state.retentionMessagesStatus = constants_1.REQUEST_STATUS.FAILED;
            (0, react_toastify_1.toast)('Retention Message Failed to Deleted', {
                type: 'error',
                autoClose: 5000,
            });
        });
    },
});
_a = exports.retentionMessageSlice.actions;
exports.default = exports.retentionMessageSlice.reducer;
