"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DrawerCloseButton = exports.MyList = exports.AppbarIcon = exports.AppbarContainerMobile = exports.AppbarContainer = exports.ChildrenWrapper = void 0;
const material_1 = require("@mui/material");
const styles_1 = require("@mui/material/styles");
const material_2 = require("@mui/material");
const theme_1 = require("@styles/theme");
exports.ChildrenWrapper = (0, styles_1.styled)(material_2.Box)(() => ({
    margin: 0,
    padding: '5px 0px',
    overflow: 'hidden',
    height: '100vh',
}));
exports.AppbarContainer = (0, styles_1.styled)(material_2.Box)(() => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '2px 8px',
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 1px 5px 0px #00000040',
    position: 'sticky',
    top: 0,
    zIndex: 99,
}));
exports.AppbarContainerMobile = (0, styles_1.styled)(material_2.Box)(() => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '2px 8px',
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 1px 5px 0px #00000040',
    position: 'sticky',
    top: 0,
    zIndex: 99,
}));
exports.AppbarIcon = (0, styles_1.styled)('img')(({ src, theme }) => ({
    src: `url(${src})`,
    width: '200px',
    [theme.breakpoints.down('md')]: {
        width: '180px',
    },
    [theme.breakpoints.down('sm')]: {
        width: '130px',
    },
}));
exports.MyList = (0, styles_1.styled)(material_1.List)(() => ({
    display: 'flex',
    flexGrow: 3,
    gap: '10px',
    justifyContent: 'center',
    alignItems: 'center',
}));
exports.DrawerCloseButton = (0, styles_1.styled)(material_1.IconButton)(() => ({
    position: 'absolute',
    top: 10,
    left: theme_1.DrawerWidth - 35,
    zIndex: 1999,
}));
