"use strict";
var _a;
var _b, _c;
Object.defineProperty(exports, "__esModule", { value: true });
exports.shiftFormsToFillOut = exports.setFormsToFillOut = exports.setKioskNumber = exports.setIsKiosk = exports.setExtension = exports.kioskSlice = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const react_toastify_1 = require("react-toastify");
const forms_1 = require("@services/forms");
const IS_KIOSK = 'IS_KIOSK';
const KIOSK_NUMBER = 'KIOSK_NUMBER';
const CURRENT_EXT = 'CURRENT_EXT';
const initialState = {
    localExtension: (_b = localStorage.getItem(CURRENT_EXT)) !== null && _b !== void 0 ? _b : 'None',
    isKiosk: localStorage.getItem(IS_KIOSK) === 'true',
    kioskNumber: (_c = localStorage.getItem(KIOSK_NUMBER)) !== null && _c !== void 0 ? _c : '',
    kioskNumbers: [],
    formsToFillOut: [],
};
exports.kioskSlice = (0, toolkit_1.createSlice)({
    name: 'kiosk',
    initialState,
    reducers: {
        setExtension: (state, action) => {
            if (action.payload) {
                localStorage.setItem(CURRENT_EXT, action.payload);
                state.localExtension = action.payload;
            }
            else {
                localStorage.setItem(CURRENT_EXT, '');
                state.localExtension = 'None';
            }
        },
        setIsKiosk: (state, action) => {
            state.isKiosk = action.payload;
            localStorage.setItem(IS_KIOSK, action.payload.toString());
            localStorage.setItem(KIOSK_NUMBER, '');
            state.kioskNumber = '';
            state.kioskNumbers = [];
            state.formsToFillOut = [];
        },
        setKioskNumber: (state, action) => {
            localStorage.setItem(KIOSK_NUMBER, action.payload);
            state.kioskNumber = action.payload;
        },
        setFormsToFillOut: (state, action) => {
            state.formsToFillOut = [...state.formsToFillOut, ...action.payload];
        },
        shiftFormsToFillOut: (state, action) => {
            state.formsToFillOut.shift();
        },
    },
    extraReducers: (builder) => {
        // Fetch form kiosk number
        builder
            .addCase(forms_1.getFormKioskNumbers.pending, (state) => { })
            .addCase(forms_1.getFormKioskNumbers.fulfilled, (state, action) => {
            if (!action.payload && !Array.isArray(action.payload)) {
                return;
            }
            state.kioskNumbers = action.payload;
        })
            .addCase(forms_1.getFormKioskNumbers.rejected, (state, action) => { });
        // Send form to kiosk
        builder
            .addCase(forms_1.sendFormToKiosk.pending, (state) => { })
            .addCase(forms_1.sendFormToKiosk.fulfilled, (state, action) => {
            (0, react_toastify_1.toast)('The form has been sent to kiosk successfully', {
                type: 'success',
                autoClose: 5000,
            });
        })
            .addCase(forms_1.sendFormToKiosk.rejected, (state, action) => {
            (0, react_toastify_1.toast)('An error occur while sending form to kiosk', {
                type: 'error',
                autoClose: 5000,
            });
        });
    },
});
// Action creators are generated for each case reducer function
_a = exports.kioskSlice.actions, exports.setExtension = _a.setExtension, exports.setIsKiosk = _a.setIsKiosk, exports.setKioskNumber = _a.setKioskNumber, exports.setFormsToFillOut = _a.setFormsToFillOut, exports.shiftFormsToFillOut = _a.shiftFormsToFillOut;
exports.default = exports.kioskSlice.reducer;
