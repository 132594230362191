"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Colors = exports.DrawerWidth = void 0;
const styles_1 = require("@mui/material/styles");
exports.DrawerWidth = 250;
exports.Colors = {
    error: '#ef5350',
    primary: '#0DA9CF',
    secondary: '#8316E2',
    contrastText: '#FFFFFF',
};
const defaultTheme = (0, styles_1.createTheme)();
let theme = (0, styles_1.createTheme)({
    palette: {
        primary: {
            main: exports.Colors.primary,
            contrastText: exports.Colors.contrastText,
        },
        secondary: {
            main: exports.Colors.secondary,
            contrastText: exports.Colors.contrastText,
        },
        info: {
            main: exports.Colors.primary,
        },
        success: {
            main: exports.Colors.contrastText,
        },
        error: {
            main: exports.Colors.error,
        },
    },
    breakpoints: {
        values: Object.assign(Object.assign({}, defaultTheme.breakpoints.values), { reminderPageBreakPoint: 800 }),
    },
    components: {
        MuiDrawer: {
            styleOverrides: {
                paper: {
                    width: exports.DrawerWidth,
                    borderRadius: '0px 0px 0px 0px',
                    borderRight: `1px solid none`,
                },
            },
        },
        MuiTabs: {
            styleOverrides: {
                indicator: {
                    backgroundColor: exports.Colors.secondary,
                },
            },
        },
        MuiListItemButton: {
            styleOverrides: {
                root: {
                    '&.Mui-selected': {
                        color: 'white',
                        backgroundColor: '#0DA9CF',
                    },
                    '&.Mui-selected:hover': {
                        color: 'white',
                        backgroundColor: '#0DA9CF',
                    },
                },
            },
        },
    },
});
exports.default = theme;
