"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const material_1 = require("@mui/material");
const react_redux_1 = require("react-redux");
const typography_1 = require("@styles/typography");
require("./newCallDialog.scss");
const call_1 = require("@services/call");
const NewCallDialog = ({ patient, isOpen, anchorEl, onClose }) => {
    const dispatch = (0, react_redux_1.useDispatch)();
    const { localExtension } = (0, react_redux_1.useSelector)((state) => state.kioskReducer);
    const makeCall = (number) => {
        onClose();
        dispatch((0, call_1.startCall)({ number, extension: localExtension }));
    };
    const guarantorPhoneNumbers = (0, react_1.useMemo)(() => {
        var _a;
        const numbers = [];
        if (patient.Guarantor && ((_a = patient.Guarantor) === null || _a === void 0 ? void 0 : _a.PatientId) !== patient.PatientId) {
            const { WirelessPhone, HomePhone, WorkPhone } = patient.Guarantor;
            if (WirelessPhone)
                numbers.push({
                    number: WirelessPhone,
                    text: 'Call Mobile',
                });
            if (HomePhone)
                numbers.push({
                    number: HomePhone,
                    text: 'Call Home',
                });
            if (WorkPhone)
                numbers.push({
                    number: WorkPhone,
                    text: 'Call Work',
                });
        }
        return numbers;
    }, [patient]);
    const patientPhoneNumbers = (0, react_1.useMemo)(() => {
        const numbers = [];
        const { WirelessPhone, HomePhone, WorkPhone } = patient;
        if (WirelessPhone)
            numbers.push({
                number: WirelessPhone,
                text: 'Call Mobile',
            });
        if (HomePhone)
            numbers.push({
                number: HomePhone,
                text: 'Call Home',
            });
        if (WorkPhone)
            numbers.push({
                number: WorkPhone,
                text: 'Call Work',
            });
        return numbers;
    }, [patient]);
    const content = (0, react_1.useMemo)(() => {
        if (guarantorPhoneNumbers.length < 1 && patientPhoneNumbers.length < 1) {
            return (0, jsx_runtime_1.jsx)(typography_1.MainTypography, { children: "Patient doesn't have any numbers on record" });
        }
        return ((0, jsx_runtime_1.jsxs)(material_1.Box, { className: 'callList', children: [guarantorPhoneNumbers.length > 0 ? ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(typography_1.MainTypography, { variant: 'h6', children: "Patients' Guarantor" }), (0, jsx_runtime_1.jsx)(material_1.List, { children: guarantorPhoneNumbers.map((data, index) => ((0, jsx_runtime_1.jsx)(material_1.ListItem, { children: (0, jsx_runtime_1.jsx)(material_1.Button, { fullWidth: true, variant: 'outlined', color: 'primary', onClick: () => makeCall(data.number), children: data.text }) }, index))) })] })) : ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, {})), patientPhoneNumbers.length > 0 ? ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsxs)(typography_1.MainTypography, { variant: 'h6', children: [patient.FirstName, " ", patient.LastName] }), (0, jsx_runtime_1.jsx)(material_1.List, { children: patientPhoneNumbers.map((data, index) => ((0, jsx_runtime_1.jsx)(material_1.ListItem, { children: (0, jsx_runtime_1.jsx)(material_1.Button, { fullWidth: true, variant: 'outlined', color: 'primary', onClick: () => makeCall(data.number), children: data.text }) }, index))) })] })) : ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, {})), (0, jsx_runtime_1.jsx)(material_1.List, { children: (0, jsx_runtime_1.jsx)(material_1.ListItem, { children: (0, jsx_runtime_1.jsx)(material_1.Button, { fullWidth: true, variant: 'text', color: 'secondary', onClick: onClose, children: "Cancel" }) }) })] }));
    }, [patient, guarantorPhoneNumbers, patientPhoneNumbers]);
    return ((0, jsx_runtime_1.jsx)(material_1.Popover, { open: isOpen, anchorEl: anchorEl, anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
        }, transformOrigin: {
            vertical: 'top',
            horizontal: 'right',
        }, onClose: onClose, children: (0, jsx_runtime_1.jsx)(material_1.Box, { className: 'newCallDialog', children: content }) }));
};
exports.default = NewCallDialog;
