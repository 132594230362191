"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.deleteUnscheduleTreatmentReminderMessage = exports.updateUnscheduleTreatmentReminderMessage = exports.fetchUnscheduleTreatmentReminderMessage = exports.createUnscheduleTreatmentReminderMessage = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const initialize_instance_1 = require("@apis/initialize.instance");
const axiosHelper_1 = require("@utils/axiosHelper");
// import { isCurrentTenant } from '@utils/isCurrentTenant';
exports.createUnscheduleTreatmentReminderMessage = (0, toolkit_1.createAsyncThunk)('unscheduleTreatmentReminder/createUnscheduleTreatmentReminder', (message, thunkApi) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const storeState = thunkApi.getState();
        const { tenantId, currentUser, token } = storeState.currentUserReducer;
        if (!token) {
            return Promise.reject('noToken');
        }
        if (!tenantId) {
            return Promise.reject('noTenantId');
        }
        if (!currentUser) {
            return Promise.reject('noCurrentUser');
        }
        message.tenantId = tenantId;
        const response = yield initialize_instance_1.unscheduleReminderInstance.post(`/${tenantId}`, Object.assign({}, message), (0, axiosHelper_1.addTokenToConfig)({}, token));
        if ((response.status === 201 || response.status === 200) && response.data) {
            thunkApi.dispatch((0, exports.fetchUnscheduleTreatmentReminderMessage)());
            return true;
        }
        return false;
    }
    catch (err) {
        throw err;
    }
}));
exports.fetchUnscheduleTreatmentReminderMessage = (0, toolkit_1.createAsyncThunk)('unscheduleTreatmentReminder/fetchUnscheduleTreatmentReminder', (_, thunkApi) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const storeState = thunkApi.getState();
        const { tenantId, token, currentUser } = storeState.currentUserReducer;
        if (!token) {
            return Promise.reject('noToken');
        }
        if (!tenantId) {
            return Promise.reject('noTenantId');
        }
        if (!currentUser) {
            return Promise.reject('noCurrentUser');
        }
        const response = yield initialize_instance_1.unscheduleReminderInstance.get(`/${tenantId}`, (0, axiosHelper_1.addTokenToConfig)({}, token));
        return response.data;
    }
    catch (err) {
        throw err;
    }
}));
exports.updateUnscheduleTreatmentReminderMessage = (0, toolkit_1.createAsyncThunk)('unscheduleTreatmentReminder/updateRetentionMessage', (message, thunkApi) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const storeState = thunkApi.getState();
        const { tenantId, token, currentUser } = storeState.currentUserReducer;
        if (!token) {
            return Promise.reject('noToken');
        }
        if (!tenantId) {
            return Promise.reject('noTenantId');
        }
        if (!currentUser) {
            return Promise.reject('noCurrentUser');
        }
        message.tenantId = tenantId;
        const response = 
        // isCurrentTenant()
        //   ?
        yield initialize_instance_1.unscheduleReminderInstance.patch(`/${message._id}/${tenantId}`, Object.assign({}, message), (0, axiosHelper_1.addTokenToConfig)({}, token));
        // : await axios.put(`/${message._id}/${tenantId}`, { ...message }, addTokenToConfig({}, token));
        if (response.status === 200 && response.data) {
            thunkApi.dispatch((0, exports.fetchUnscheduleTreatmentReminderMessage)());
            return true;
        }
        return false;
    }
    catch (err) {
        throw err;
    }
}));
exports.deleteUnscheduleTreatmentReminderMessage = (0, toolkit_1.createAsyncThunk)('unscheduleTreatmentReminder/deleteUnscheduleTreatmentReminder', (messageId, thunkApi) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const storeState = thunkApi.getState();
        const { token, currentUser, tenantId } = storeState.currentUserReducer;
        if (!token) {
            return Promise.reject('noToken');
        }
        if (!tenantId) {
            return Promise.reject('noTenantId');
        }
        if (!currentUser) {
            return Promise.reject('noCurrentUser');
        }
        const response = yield initialize_instance_1.unscheduleReminderInstance.delete(`/${messageId}/${tenantId}`, (0, axiosHelper_1.addTokenToConfig)({}, token));
        if (response.status === 200) {
            thunkApi.dispatch((0, exports.fetchUnscheduleTreatmentReminderMessage)());
            return true;
        }
        return false;
    }
    catch (err) {
        throw err;
    }
}));
