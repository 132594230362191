"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
const react_toastify_1 = require("react-toastify");
const toolkit_1 = require("@reduxjs/toolkit");
const procedure_1 = require("@services/procedure");
const constants_1 = require("@appConstants/constants");
const initialState = {
    procedures: [],
    procedureStatus: constants_1.REQUEST_STATUS.PENDING,
};
const procedureSlice = (0, toolkit_1.createSlice)({
    name: 'procedure',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(procedure_1.fetchProcedures.pending, (state) => {
            state.procedureStatus = constants_1.REQUEST_STATUS.PENDING;
        })
            .addCase(procedure_1.fetchProcedures.fulfilled, (state, action) => {
            if (action.payload && Array.isArray(action.payload)) {
                state.procedures = [...action.payload];
                state.procedureStatus = constants_1.REQUEST_STATUS.SUCCEEDED;
            }
            else {
                state.procedureStatus = constants_1.REQUEST_STATUS.FAILED;
                (0, react_toastify_1.toast)('Error loading the procedures', {
                    type: 'error',
                    autoClose: 5000,
                });
            }
        })
            .addCase(procedure_1.fetchProcedures.rejected, (state, action) => {
            state.procedureStatus = constants_1.REQUEST_STATUS.FAILED;
            (0, react_toastify_1.toast)('Error loading the procedures', {
                type: 'error',
                autoClose: 5000,
            });
        });
    },
});
_a = procedureSlice.actions;
exports.default = procedureSlice.reducer;
