"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_redux_1 = require("react-redux");
const material_1 = require("@mui/material");
const currentUserSlice_1 = require("@redux/reducers/currentUserSlice");
// import { initializeInstances } from '@apis/initialize.instance';
require("./tenantChooser.scss");
const TenantChooser = ({ tenants }) => {
    const dispatch = (0, react_redux_1.useDispatch)();
    const handleClick = (id) => {
        // Cookies.set('currentUserTenantId', id);
        // initializeInstances();
        dispatch((0, currentUserSlice_1.setCurrentUserTenantId)(id));
    };
    return ((0, jsx_runtime_1.jsx)(material_1.Box, { className: 'tenant-chooser-wrapper', children: (0, jsx_runtime_1.jsx)(material_1.Card, { className: 'chooser-container', children: tenants &&
                tenants.map((tenant) => {
                    return ((0, jsx_runtime_1.jsxs)(material_1.Button, { onClick: () => handleClick(tenant === null || tenant === void 0 ? void 0 : tenant._id), children: [(0, jsx_runtime_1.jsx)("b", { children: tenant === null || tenant === void 0 ? void 0 : tenant.tenantName }), " - ", tenant === null || tenant === void 0 ? void 0 : tenant._id] }, tenant._id));
                }) }) }));
};
exports.default = TenantChooser;
