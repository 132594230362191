"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IOSSwitch = exports.RectangleButton = exports.RoundedButton = void 0;
const material_1 = require("@mui/material");
const styles_1 = require("@mui/material/styles");
exports.RoundedButton = (0, styles_1.styled)(material_1.Button)(() => ({
    borderRadius: 21,
    textTransform: 'capitalize',
}));
exports.RectangleButton = (0, styles_1.styled)(material_1.Button)(() => ({
    borderRadius: '4px',
    textTransform: 'capitalize',
    padding: '6px 16px',
    color: '#FFFFFF',
    backgroundColor: '#0DA9CF',
    boxShadow: `0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)`,
}));
exports.IOSSwitch = (0, styles_1.styled)(material_1.Switch)(() => ({
    width: 48,
    height: 22,
    padding: 0,
    overflow: 'visible',
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(24px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: '#0DA9CF',
                border: '1px solid #0DA9CF',
                opacity: 1,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color: '#3C3C4399',
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: 0.7,
            background: '#3C3C4399',
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 20,
        height: 20,
        background: '#3C3C4399',
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: 'white',
        border: '1px solid  #3C3C4399',
        opacity: 1,
    },
}));
