"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.setGlobalRole = exports.setToken = exports.setCurrentUserTenantId = exports.setCurrentUser = exports.currentUserSlice = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const react_toastify_1 = require("react-toastify");
const googleDrive_1 = require("@services/googleDrive");
const currentUser_1 = require("@services/currentUser");
const initialState = {
    currentUser: undefined,
    tenantId: undefined,
    globalRole: undefined,
    token: undefined,
    currentApiVersion: '',
};
exports.currentUserSlice = (0, toolkit_1.createSlice)({
    name: 'currentUser',
    initialState,
    reducers: {
        setCurrentUser: (state, action) => {
            state.currentUser = action.payload;
        },
        setCurrentUserTenantId: (state, action) => {
            state.tenantId = action.payload;
        },
        setToken: (state, action) => {
            state.token = action.payload;
        },
        setGlobalRole: (state, action) => {
            state.globalRole = action.payload;
        },
    },
    extraReducers(builder) {
        // Fetch API Version
        builder.addCase(currentUser_1.fetchApiVersion.fulfilled, (state, action) => {
            if (action.payload) {
                state.currentApiVersion = action.payload;
            }
        });
        // Authneticate Google Drive
        builder
            .addCase(googleDrive_1.setUpGoogleDrive.pending, (state) => { })
            .addCase(googleDrive_1.setUpGoogleDrive.fulfilled, (state, action) => {
            if (!action.payload) {
                (0, react_toastify_1.toast)('Error setting up google drive. Please try again', {
                    type: 'error',
                    autoClose: 5000,
                });
                return;
            }
            window.location.replace(action.payload);
        })
            .addCase(googleDrive_1.setUpGoogleDrive.rejected, (state, action) => {
            (0, react_toastify_1.toast)('Error setting up google drive. Please try again', {
                type: 'error',
                autoClose: 5000,
            });
        });
    },
});
// Action creators are generated for each case reducer function
_a = exports.currentUserSlice.actions, exports.setCurrentUser = _a.setCurrentUser, exports.setCurrentUserTenantId = _a.setCurrentUserTenantId, exports.setToken = _a.setToken, exports.setGlobalRole = _a.setGlobalRole;
exports.default = exports.currentUserSlice.reducer;
