"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getRandomThemeColor = void 0;
const themeColors = [
    '#d4e157',
    '#7e57c2',
    '#42a5f5',
    '#29b6f6',
    '#ef5350',
    '#66bb6a',
    '#9ccc65',
    '#ec407a',
    '#5c6bc0',
    '#ab47bc',
    '#26c6da',
    '#26a69a',
    '#66bb6a',
    '#9ccc65',
    '#d4e157',
    '#ffca28',
    '#ffa726',
    '#ff7043',
];
const getRandomThemeColor = () => {
    return themeColors[Math.floor(Math.random() * themeColors.length)];
};
exports.getRandomThemeColor = getRandomThemeColor;
