"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.syncServiceForceInstallSlice = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const react_toastify_1 = require("react-toastify");
const constants_1 = require("@appConstants/constants");
const syncServiceForceInstall_1 = require("@services/syncServiceForceInstall");
const initialState = {
    forceInstallStatus: constants_1.REQUEST_STATUS.IDLE,
    title: '',
    message: '',
    severity: '',
};
exports.syncServiceForceInstallSlice = (0, toolkit_1.createSlice)({
    name: 'syncServiceForceInstall',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(syncServiceForceInstall_1.installService.pending, (state) => {
            state.forceInstallStatus = constants_1.REQUEST_STATUS.PENDING;
        })
            .addCase(syncServiceForceInstall_1.installService.fulfilled, (state, action) => {
            if (!action.payload) {
                state.forceInstallStatus = constants_1.REQUEST_STATUS.FAILED;
                (0, react_toastify_1.toast)('Error while doing the Force Sync-Service Update', {
                    type: 'error',
                });
                return;
            }
            state.forceInstallStatus = constants_1.REQUEST_STATUS.SUCCEEDED;
            (0, react_toastify_1.toast)('Sync Service force install was successfull for the tenant', {
                type: 'success',
            });
        })
            .addCase(syncServiceForceInstall_1.installService.rejected, (state, action) => {
            state.forceInstallStatus = constants_1.REQUEST_STATUS.FAILED;
            (0, react_toastify_1.toast)('Error while doing the Force Sync-Service Update', {
                type: 'error',
            });
        });
    },
});
// Action creators are generated for each case reducer function
_a = exports.syncServiceForceInstallSlice.actions;
exports.default = exports.syncServiceForceInstallSlice.reducer;
