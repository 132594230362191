"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const icons_material_1 = require("@mui/icons-material");
const material_1 = require("@mui/material");
const react_redux_1 = require("react-redux");
const auth0_react_1 = require("@auth0/auth0-react");
const constants_1 = require("@appConstants/constants");
const socket_1 = require("@services/socket");
const callsSlice_1 = require("@redux/reducers/callsSlice");
const conversationSlice_1 = require("@redux/reducers/conversationSlice");
const currentUserSlice_1 = require("@redux/reducers/currentUserSlice");
require("./persistantConnector.scss");
const PersistantConnector = () => {
    const dispatch = (0, react_redux_1.useDispatch)();
    const { tenantId, token } = (0, react_redux_1.useSelector)((state) => state.currentUserReducer);
    const { localExtension } = (0, react_redux_1.useSelector)((state) => state.kioskReducer);
    const { getAccessTokenSilently } = (0, auth0_react_1.useAuth0)();
    let content;
    let reconnectInterval;
    const audio = new Audio('/audio/ding.mp3');
    const parentclasses = ['parent'];
    const num = new Date().getHours();
    parentclasses.push(`skgrd${String(num).padStart(2, '0')}`);
    const [messageService, setMessageService] = (0, react_1.useState)(false);
    const [connectionStatus, setConnectionStatus] = (0, react_1.useState)();
    (0, react_1.useEffect)(() => {
        if (!messageService) {
            reconnectInterval = setInterval(() => {
                initMessageService();
            }, constants_1.FIVE_SECOND);
        }
        return () => clearInterval(reconnectInterval);
    }, [connectionStatus, tenantId, token, messageService]);
    const generateToken = () => __awaiter(void 0, void 0, void 0, function* () {
        const newToken = yield getAccessTokenSilently({
            authorizationParams: {
                audience: constants_1.AUTH_AUDIENCE,
                scope: constants_1.AUTH_SCOPES,
            },
        });
        if (newToken) {
            dispatch((0, currentUserSlice_1.setToken)(newToken));
        }
    });
    const messageCallback = (message) => {
        var _a, _b;
        switch (message.MessageType) {
            case 'closed':
                generateToken();
                setMessageService(false);
                setConnectionStatus(constants_1.PERSISTANT_CONNECTION_STATUS.CLOSED);
                break;
            case 'welcome':
                setMessageService(true);
                setConnectionStatus(constants_1.PERSISTANT_CONNECTION_STATUS.CONNECTED);
                break;
            case 'incoming-call':
                if (message.data && message.data.extension && ((_a = message.data.extension) === null || _a === void 0 ? void 0 : _a.toLowerCase()) === localExtension.toLowerCase()) {
                    dispatch((0, callsSlice_1.setActiveCall)({ callInfo: message.data }));
                }
                break;
            case 'caller-update':
                if (message.data) {
                    dispatch((0, callsSlice_1.setActiveCallExtraInfo)({ callInfo: message.data }));
                }
                break;
            case 'call-ended':
                if (message.data && message.data.extension && ((_b = message.data.extension) === null || _b === void 0 ? void 0 : _b.toLowerCase()) === localExtension.toLowerCase()) {
                    dispatch((0, callsSlice_1.setActiveCall)(undefined));
                }
                break;
            case 'incoming-sms':
                if (message.data) {
                    dispatch((0, conversationSlice_1.addIncomingMessage)([message.data]));
                    if (message.data.direction === 'incoming') {
                        audio.play();
                    }
                }
                break;
            case 'extraConvoInfo':
                if (message.data) {
                    dispatch((0, conversationSlice_1.addMessagesExtra)(message.data));
                }
                break;
            default:
                break;
        }
    };
    const initMessageService = () => __awaiter(void 0, void 0, void 0, function* () {
        if (tenantId && token) {
            // if (isCurrentTenant()) {
            (0, socket_1.init)(tenantId, token, messageCallback, false);
            // } else {
            //   initOld(tenantId, token, messageCallback, false);
            // }
            setMessageService(true);
        }
    });
    if (connectionStatus === constants_1.PERSISTANT_CONNECTION_STATUS.CLOSED) {
        content = ((0, jsx_runtime_1.jsxs)(material_1.Box, { className: 'lost-connected', children: [(0, jsx_runtime_1.jsx)("span", { children: "Trying to reconnect" }), (0, jsx_runtime_1.jsx)(icons_material_1.CloudOff, { className: 'lost-connected-icon' })] }));
    }
    else if (connectionStatus === constants_1.PERSISTANT_CONNECTION_STATUS.CONNECTED) {
        content = ((0, jsx_runtime_1.jsx)(material_1.Box, { className: 'connected-container', children: (0, jsx_runtime_1.jsx)(material_1.Box, { className: parentclasses.join(' '), children: (0, jsx_runtime_1.jsx)(icons_material_1.Cloud, { className: 'connected-icon' }) }) }));
    }
    else {
        content = ((0, jsx_runtime_1.jsx)(material_1.Box, { className: 'connected-container', children: (0, jsx_runtime_1.jsx)(material_1.Box, { className: parentclasses.join(' '), children: (0, jsx_runtime_1.jsx)(icons_material_1.CloudQueue, { className: 'connecting-icon' }) }) }));
    }
    return (0, jsx_runtime_1.jsx)(material_1.Box, { className: 'persistantConnectorContainer', children: content });
};
exports.default = PersistantConnector;
