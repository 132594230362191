"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.statusMapperSlice = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const react_toastify_1 = require("react-toastify");
const constants_1 = require("@appConstants/constants");
const statusMapper_1 = require("@services/statusMapper");
const initialState = {
    tenantStatuses: [],
    tenantStatusesLoading: constants_1.REQUEST_STATUS.IDLE,
};
exports.statusMapperSlice = (0, toolkit_1.createSlice)({
    name: 'statusMapper',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(statusMapper_1.fetchStatusMapper.pending, (state) => {
            state.tenantStatuses = [];
            state.tenantStatusesLoading = constants_1.REQUEST_STATUS.PENDING;
        })
            .addCase(statusMapper_1.fetchStatusMapper.fulfilled, (state, action) => {
            if (!action.payload || !Array.isArray(action.payload)) {
                state.tenantStatusesLoading = constants_1.REQUEST_STATUS.FAILED;
                (0, react_toastify_1.toast)('Error loading the status mapper details', {
                    type: 'error',
                    autoClose: 5000,
                });
                return;
            }
            state.tenantStatuses = action.payload;
            state.tenantStatusesLoading = constants_1.REQUEST_STATUS.SUCCEEDED;
        })
            .addCase(statusMapper_1.fetchStatusMapper.rejected, (state, action) => {
            state.tenantStatusesLoading = constants_1.REQUEST_STATUS.FAILED;
            (0, react_toastify_1.toast)('Error loading the status mapper details', {
                type: 'error',
                autoClose: 5000,
            });
        });
        builder
            .addCase(statusMapper_1.updateStatusMapper.pending, (state) => {
            state.tenantStatusesLoading = constants_1.REQUEST_STATUS.PENDING;
        })
            .addCase(statusMapper_1.updateStatusMapper.fulfilled, (state, action) => {
            if (!action.payload || !Array.isArray(action.payload)) {
                state.tenantStatusesLoading = constants_1.REQUEST_STATUS.FAILED;
                (0, react_toastify_1.toast)('Error loading the status mapper details', {
                    type: 'error',
                    autoClose: 5000,
                });
                return;
            }
            state.tenantStatuses = action.payload;
            state.tenantStatusesLoading = constants_1.REQUEST_STATUS.SUCCEEDED;
            (0, react_toastify_1.toast)('Status Mapper has been updated successfully', {
                type: 'success',
                autoClose: 5000,
            });
        })
            .addCase(statusMapper_1.updateStatusMapper.rejected, (state, action) => {
            state.tenantStatusesLoading = constants_1.REQUEST_STATUS.FAILED;
            (0, react_toastify_1.toast)('Error loading the status mapper details', {
                type: 'error',
                autoClose: 5000,
            });
        });
    },
});
// Action creators are generated for each case reducer function
_a = exports.statusMapperSlice.actions;
exports.default = exports.statusMapperSlice.reducer;
