"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ROUTES = exports.ADMIN_NAVBAR_OPTIONS = exports.TENANT_NAVBAR_OPTIONS = void 0;
exports.TENANT_NAVBAR_OPTIONS = [
    { route: '/home', name: 'Home' },
    { route: '/conversations', name: 'Conversations' },
    { route: '/reminders', name: 'Reminders & Notifications' },
];
exports.ADMIN_NAVBAR_OPTIONS = [
    { route: '/tenants', name: 'Tenants' },
    { route: '/newtenant', name: 'New Tenant' },
];
const AUTH_ROUTES = {
    login: '/',
};
const PROTECTED_ROUTES_NAMES = {
    root: '/',
    home: '/home',
    conversation: '/conversations',
    reminders: '/reminders',
    payments: '/payments',
    settings: '/settings',
    statusMapper: '/status-mapper',
    userManagment: '/user-management',
    verifyStripe: '/verify-stripe/:tenantId',
    tenantProfile: '/tenant-profile',
};
const ADMIN_ROUTES_NAMES = {
    tenants: '/tenants',
    newTenant: '/newtenant',
    statusMapper: '/status-mapper',
    userManagment: '/user-management',
};
const PUBLIC_ROUTES_NAMES = {
    formSubmission: '/form/submission/:groupId',
};
exports.ROUTES = {
    AUTH_ROUTES,
    PROTECTED_ROUTES_NAMES,
    PUBLIC_ROUTES_NAMES,
    ADMIN_ROUTES_NAMES,
};
