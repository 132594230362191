"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const yup = __importStar(require("yup"));
const formik_1 = require("formik");
const material_1 = require("@mui/material");
require("./userDialog.scss");
const UserDialog = ({ open, onClose, data, title, deleteFlag, btnText, handleSubmit }) => {
    const validationSchema = yup.object({
        email: yup.string().email('Enter a valid email').required('Email is required'),
        name: yup.string().required('Name is required'),
    });
    const formik = (0, formik_1.useFormik)({
        initialValues: {
            _id: data ? data === null || data === void 0 ? void 0 : data._id : '',
            name: data ? data === null || data === void 0 ? void 0 : data.name : '',
            email: data ? data.email : '',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            formSubmit(values);
        },
    });
    const handleClose = () => {
        onClose();
    };
    const formSubmit = (val) => {
        handleSubmit(val);
    };
    return ((0, jsx_runtime_1.jsxs)(material_1.Dialog, { open: open, children: [(0, jsx_runtime_1.jsx)(material_1.DialogTitle, { children: title }), (0, jsx_runtime_1.jsxs)("form", { onSubmit: formik.handleSubmit, className: 'userForm', children: [(0, jsx_runtime_1.jsxs)(material_1.DialogContent, { children: [(0, jsx_runtime_1.jsx)(material_1.InputLabel, { htmlFor: 'name', children: "Name" }), (0, jsx_runtime_1.jsx)(material_1.TextField, { fullWidth: true, id: 'name', name: 'name', type: 'name', autoFocus: true, margin: 'dense', variant: 'outlined', value: formik.values.name, onChange: formik.handleChange, error: formik.touched.name && Boolean(formik.errors.name), helperText: formik.touched.name && formik.errors.name, disabled: deleteFlag }), (0, jsx_runtime_1.jsx)(material_1.InputLabel, { htmlFor: 'email', children: "Email" }), (0, jsx_runtime_1.jsx)(material_1.TextField, { fullWidth: true, id: 'email', name: 'email', margin: 'dense', variant: 'outlined', value: formik.values.email, onChange: formik.handleChange, error: formik.touched.email && Boolean(formik.errors.email), helperText: formik.touched.email && formik.errors.email, disabled: deleteFlag }), deleteFlag && (0, jsx_runtime_1.jsx)(material_1.Typography, { children: "Are you sure to delete this user?" })] }), (0, jsx_runtime_1.jsxs)(material_1.DialogActions, { className: 'actions', children: [(0, jsx_runtime_1.jsx)(material_1.Button, { color: 'primary', variant: 'contained', type: 'submit', children: btnText }), (0, jsx_runtime_1.jsx)(material_1.Button, { onClick: handleClose, color: 'primary', children: "Close" })] })] })] }));
};
exports.default = UserDialog;
