"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.resetPayments = exports.paymentSlice = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const react_toastify_1 = require("react-toastify");
const constants_1 = require("@appConstants/constants");
const payments_1 = require("@services/payments");
const initialState = {
    list: [],
    paymentStatus: constants_1.REQUEST_STATUS.IDLE,
    createStripeAccountStatus: constants_1.REQUEST_STATUS.IDLE,
    haveMore: true,
    generateInvoiceUrlStatus: constants_1.REQUEST_STATUS.IDLE,
};
exports.paymentSlice = (0, toolkit_1.createSlice)({
    name: 'payment',
    initialState,
    reducers: {
        resetPayments: (state) => {
            state.list = [];
            (state.haveMore = true), (state.paymentStatus = constants_1.REQUEST_STATUS.IDLE);
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(payments_1.createStripeAccount.pending, (state) => {
            state.createStripeAccountStatus = constants_1.REQUEST_STATUS.PENDING;
        })
            .addCase(payments_1.createStripeAccount.fulfilled, (state, action) => {
            if (action.payload) {
                state.createStripeAccountStatus = constants_1.REQUEST_STATUS.SUCCEEDED;
                window.location.href = action.payload.url;
            }
            else {
                state.createStripeAccountStatus = constants_1.REQUEST_STATUS.FAILED;
                (0, react_toastify_1.toast)('Error creating the stripe account', {
                    type: 'error',
                    autoClose: 5000,
                });
            }
        })
            .addCase(payments_1.createStripeAccount.rejected, (state, action) => {
            state.createStripeAccountStatus = constants_1.REQUEST_STATUS.FAILED;
            (0, react_toastify_1.toast)('Error creating the stripe account', {
                type: 'error',
                autoClose: 5000,
            });
        });
        // request payments
        builder
            .addCase(payments_1.requestPayments.pending, (state) => {
            state.paymentStatus = constants_1.REQUEST_STATUS.PENDING;
        })
            .addCase(payments_1.requestPayments.fulfilled, (state, action) => {
            state.paymentStatus = constants_1.REQUEST_STATUS.SUCCEEDED;
            if (action.payload && Array.isArray(action.payload.list)) {
                const paymentList = [...state.list, ...action.payload.list];
                if (paymentList.length > 0) {
                    const key = 'id';
                    const unique = [...new Map(paymentList.map((item) => [item[key], item])).values()];
                    state.list = unique;
                    state.haveMore = action.payload.haveMore;
                }
            }
            else {
                state.paymentStatus = constants_1.REQUEST_STATUS.FAILED;
                (0, react_toastify_1.toast)('Error in getting payments', {
                    type: 'error',
                    autoClose: 5000,
                });
            }
        })
            .addCase(payments_1.requestPayments.rejected, (state, action) => {
            state.paymentStatus = constants_1.REQUEST_STATUS.FAILED;
            (0, react_toastify_1.toast)('Error in getting payments', {
                type: 'error',
                autoClose: 5000,
            });
        });
        //generateInvoice
        builder
            .addCase(payments_1.generateInvoice.pending, (state) => {
            state.generateInvoiceUrlStatus = constants_1.REQUEST_STATUS.PENDING;
        })
            .addCase(payments_1.generateInvoice.fulfilled, (state, action) => {
            if (action.payload) {
                state.generateInvoiceUrlStatus = constants_1.REQUEST_STATUS.SUCCEEDED;
                (0, react_toastify_1.toast)('The invoice has been generated and sent successfully', {
                    type: 'success',
                    autoClose: 5000,
                });
            }
            else {
                state.generateInvoiceUrlStatus = constants_1.REQUEST_STATUS.FAILED;
                (0, react_toastify_1.toast)('Error in generating invoice', {
                    type: 'error',
                    autoClose: 5000,
                });
            }
        })
            .addCase(payments_1.generateInvoice.rejected, (state, action) => {
            state.generateInvoiceUrlStatus = constants_1.REQUEST_STATUS.FAILED;
            (0, react_toastify_1.toast)('Error in generating invoice', {
                type: 'error',
                autoClose: 5000,
            });
        });
    },
});
// Action creators are generated for each case reducer function
exports.resetPayments = exports.paymentSlice.actions.resetPayments;
exports.default = exports.paymentSlice.reducer;
