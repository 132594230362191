"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.missedCallSlice = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const react_toastify_1 = require("react-toastify");
const missCallMessage_1 = require("@services/missCallMessage");
const constants_1 = require("@appConstants/constants");
const initialState = {
    missedCallMessage: {
        content: '',
        enabled: false,
        tenantId: '',
        createdDate: new Date(),
        updatedDate: new Date(),
    },
    getMissCallStatus: constants_1.REQUEST_STATUS.PENDING,
};
exports.missedCallSlice = (0, toolkit_1.createSlice)({
    name: 'missCallMessage',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(missCallMessage_1.fetchMissCallMessage.pending, (state) => {
            state.getMissCallStatus = constants_1.REQUEST_STATUS.PENDING;
        })
            .addCase(missCallMessage_1.fetchMissCallMessage.fulfilled, (state, action) => {
            if (action.payload) {
                state.missedCallMessage = Object.assign({}, action.payload);
                state.getMissCallStatus = constants_1.REQUEST_STATUS.SUCCEEDED;
            }
            else {
                state.getMissCallStatus = constants_1.REQUEST_STATUS.FAILED;
                (0, react_toastify_1.toast)('Error loading the miss call messages', {
                    type: 'error',
                    autoClose: 5000,
                });
            }
        })
            .addCase(missCallMessage_1.fetchMissCallMessage.rejected, (state, action) => {
            state.getMissCallStatus = constants_1.REQUEST_STATUS.FAILED;
            (0, react_toastify_1.toast)('Error loading the miss call messages', {
                type: 'error',
                autoClose: 5000,
            });
        });
        builder
            .addCase(missCallMessage_1.createMissCallMessage.pending, (state) => {
            state.getMissCallStatus = constants_1.REQUEST_STATUS.PENDING;
        })
            .addCase(missCallMessage_1.createMissCallMessage.fulfilled, (state, action) => {
            if (action.payload) {
                state.getMissCallStatus = constants_1.REQUEST_STATUS.SUCCEEDED;
                (0, react_toastify_1.toast)('Missed call message template has been created successfully.', {
                    type: 'success',
                    autoClose: 5000,
                });
            }
            else {
                state.getMissCallStatus = constants_1.REQUEST_STATUS.FAILED;
                (0, react_toastify_1.toast)('Error occur while creating missed call message template.', {
                    type: 'error',
                    autoClose: 5000,
                });
            }
        })
            .addCase(missCallMessage_1.createMissCallMessage.rejected, (state, action) => {
            state.getMissCallStatus = constants_1.REQUEST_STATUS.FAILED;
            (0, react_toastify_1.toast)('Error occur while creating missed call message template.', {
                type: 'error',
                autoClose: 5000,
            });
        });
        builder
            .addCase(missCallMessage_1.updateMissCallMessage.pending, (state) => {
            state.getMissCallStatus = constants_1.REQUEST_STATUS.PENDING;
        })
            .addCase(missCallMessage_1.updateMissCallMessage.fulfilled, (state, action) => {
            if (action.payload) {
                state.getMissCallStatus = constants_1.REQUEST_STATUS.SUCCEEDED;
                (0, react_toastify_1.toast)('Missed call message template updated successfully.', {
                    type: 'success',
                    autoClose: 5000,
                });
            }
            else {
                state.getMissCallStatus = constants_1.REQUEST_STATUS.FAILED;
                (0, react_toastify_1.toast)('Error occur while updating missed call message template.', {
                    type: 'error',
                    autoClose: 5000,
                });
            }
        })
            .addCase(missCallMessage_1.updateMissCallMessage.rejected, (state, action) => {
            state.getMissCallStatus = constants_1.REQUEST_STATUS.FAILED;
            (0, react_toastify_1.toast)('Error occur while updating missed call message template.', {
                type: 'error',
                autoClose: 5000,
            });
        });
    },
});
_a = exports.missedCallSlice.actions;
exports.default = exports.missedCallSlice.reducer;
