"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getThemeColor = exports.getLastUserName = exports.getInitials = void 0;
const getInitials = (name) => {
    if (!name) {
        name = '';
    }
    var matches = name.match(/\b(\w)/g);
    let letters = '';
    if (matches && matches.length > 0) {
        letters = matches.join('');
    }
    return letters;
};
exports.getInitials = getInitials;
const getLastUserName = (userId, basicUsers) => {
    if (userId === 'system') {
        return 'Automated';
    }
    let returnValue = '';
    if (basicUsers) {
        basicUsers.forEach((element) => {
            if (element.UserId == userId) {
                returnValue = element.name;
            }
        });
    }
    return returnValue;
};
exports.getLastUserName = getLastUserName;
const getThemeColor = (userId, basicUsers) => {
    if (userId === 'system') {
        return '#7f7f7f';
    }
    let returnValue = '';
    if (basicUsers) {
        basicUsers.forEach((element) => {
            if (element.UserId == userId) {
                returnValue = element.themeColor;
            }
        });
    }
    return returnValue;
};
exports.getThemeColor = getThemeColor;
