"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.formSlice = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const react_toastify_1 = require("react-toastify");
const constants_1 = require("@appConstants/constants");
const forms_1 = require("@services/forms");
const formCollection_1 = require("@services/formCollection");
const initialState = {
    needsFormFolder: false,
    availableForms: [],
    getAvailableFormsStatus: constants_1.REQUEST_STATUS.IDLE,
    getDocumentTypesStatus: constants_1.REQUEST_STATUS.IDLE,
    documentTypes: [],
    formExpandedData: undefined,
    getFormExpandedStatus: constants_1.REQUEST_STATUS.IDLE,
    mappings: undefined,
    formCollections: [],
    getFormCollectionStatus: constants_1.REQUEST_STATUS.IDLE,
    createFormCollectionStatus: constants_1.REQUEST_STATUS.IDLE,
};
exports.formSlice = (0, toolkit_1.createSlice)({
    name: 'forms',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // Fetch document types for forms
        builder
            .addCase(forms_1.fetchDocumentTypes.pending, (state) => {
            state.getDocumentTypesStatus = constants_1.REQUEST_STATUS.PENDING;
        })
            .addCase(forms_1.fetchDocumentTypes.fulfilled, (state, action) => {
            if (!action.payload && !Array.isArray(action.payload)) {
                state.getDocumentTypesStatus = constants_1.REQUEST_STATUS.FAILED;
                (0, react_toastify_1.toast)('Error loading the document types for forms', {
                    type: 'error',
                    autoClose: 5000,
                });
                return;
            }
            state.getDocumentTypesStatus = constants_1.REQUEST_STATUS.SUCCEEDED;
            state.documentTypes = action.payload;
        })
            .addCase(forms_1.fetchDocumentTypes.rejected, (state, action) => {
            state.getDocumentTypesStatus = constants_1.REQUEST_STATUS.FAILED;
            (0, react_toastify_1.toast)('Error loading the document types for forms', {
                type: 'error',
                autoClose: 5000,
            });
        });
        // Fetch current tenants forms
        builder
            .addCase(forms_1.fetchTenantAvailableForms.pending, (state) => {
            state.needsFormFolder = false;
            state.availableForms = [];
            state.getAvailableFormsStatus = constants_1.REQUEST_STATUS.PENDING;
        })
            .addCase(forms_1.fetchTenantAvailableForms.fulfilled, (state, action) => {
            if (!action.payload && !Array.isArray(action.payload)) {
                state.getAvailableFormsStatus = constants_1.REQUEST_STATUS.FAILED;
                state.needsFormFolder = false;
                (0, react_toastify_1.toast)('Error loading the available forms', {
                    type: 'error',
                    autoClose: 5000,
                });
                return;
            }
            state.getAvailableFormsStatus = constants_1.REQUEST_STATUS.SUCCEEDED;
            state.availableForms = action.payload;
        })
            .addCase(forms_1.fetchTenantAvailableForms.rejected, (state, action) => {
            state.getAvailableFormsStatus = constants_1.REQUEST_STATUS.FAILED;
            state.needsFormFolder = true;
        });
        // Fetch current admin forms
        builder
            .addCase(forms_1.fetchAvailableForms.pending, (state) => {
            state.needsFormFolder = false;
            state.availableForms = [];
            state.getAvailableFormsStatus = constants_1.REQUEST_STATUS.PENDING;
        })
            .addCase(forms_1.fetchAvailableForms.fulfilled, (state, action) => {
            if (!action.payload && !Array.isArray(action.payload)) {
                state.getAvailableFormsStatus = constants_1.REQUEST_STATUS.FAILED;
                state.needsFormFolder = false;
                (0, react_toastify_1.toast)('Error loading the available forms', {
                    type: 'error',
                    autoClose: 5000,
                });
                return;
            }
            state.getAvailableFormsStatus = constants_1.REQUEST_STATUS.SUCCEEDED;
            state.availableForms = action.payload;
        })
            .addCase(forms_1.fetchAvailableForms.rejected, (state, action) => {
            state.getAvailableFormsStatus = constants_1.REQUEST_STATUS.FAILED;
            state.needsFormFolder = true;
        });
        // Create tenant from folder
        builder
            .addCase(forms_1.createFormFolder.pending, (state) => {
            state.needsFormFolder = false;
            state.availableForms = [];
            state.getAvailableFormsStatus = constants_1.REQUEST_STATUS.PENDING;
        })
            .addCase(forms_1.createFormFolder.fulfilled, (state, action) => {
            if (!action.payload) {
                state.needsFormFolder = true;
                state.availableForms = [];
                state.getAvailableFormsStatus = constants_1.REQUEST_STATUS.FAILED;
                (0, react_toastify_1.toast)('Error occur while creating the form folder', {
                    type: 'error',
                    autoClose: 5000,
                });
                return;
            }
            (0, react_toastify_1.toast)('Form folder has been created successfully', {
                type: 'success',
                autoClose: 5000,
            });
        })
            .addCase(forms_1.createFormFolder.rejected, (state, action) => {
            state.needsFormFolder = false;
            state.availableForms = [];
            state.getAvailableFormsStatus = constants_1.REQUEST_STATUS.FAILED;
            (0, react_toastify_1.toast)('Error occur while creating the form folder', {
                type: 'error',
                autoClose: 5000,
            });
        });
        // Add tenant form
        builder
            .addCase(forms_1.addFormToFolder.pending, (state) => {
            state.needsFormFolder = false;
            state.availableForms = [];
            state.getAvailableFormsStatus = constants_1.REQUEST_STATUS.PENDING;
        })
            .addCase(forms_1.addFormToFolder.fulfilled, (state, action) => {
            if (!action.payload) {
                state.needsFormFolder = false;
                state.getAvailableFormsStatus = constants_1.REQUEST_STATUS.FAILED;
                (0, react_toastify_1.toast)('Error occur while adding the form', {
                    type: 'error',
                    autoClose: 5000,
                });
                return;
            }
            (0, react_toastify_1.toast)('The form has been added successfully', {
                type: 'success',
                autoClose: 5000,
            });
        })
            .addCase(forms_1.addFormToFolder.rejected, (state, action) => {
            state.needsFormFolder = false;
            state.getAvailableFormsStatus = constants_1.REQUEST_STATUS.FAILED;
            (0, react_toastify_1.toast)('Error occur while adding the form', {
                type: 'error',
                autoClose: 5000,
            });
        });
        // Get form expanded data
        builder
            .addCase(forms_1.fetchFormExpandedData.pending, (state) => {
            state.formExpandedData = undefined;
            state.getFormExpandedStatus = constants_1.REQUEST_STATUS.PENDING;
        })
            .addCase(forms_1.fetchFormExpandedData.fulfilled, (state, action) => {
            if (!action.payload) {
                state.getFormExpandedStatus = constants_1.REQUEST_STATUS.FAILED;
                (0, react_toastify_1.toast)('Error occur while getting the form data', {
                    type: 'error',
                    autoClose: 5000,
                });
                return;
            }
            state.getFormExpandedStatus = constants_1.REQUEST_STATUS.SUCCEEDED;
            state.formExpandedData = action.payload;
        })
            .addCase(forms_1.fetchFormExpandedData.rejected, (state, action) => {
            state.getFormExpandedStatus = constants_1.REQUEST_STATUS.FAILED;
            (0, react_toastify_1.toast)('Error occur while getting the form data', {
                type: 'error',
                autoClose: 5000,
            });
        });
        // Get form expanded data
        builder
            .addCase(forms_1.fetchFormMapping.pending, (state) => {
            state.mappings = undefined;
            state.getFormExpandedStatus = constants_1.REQUEST_STATUS.PENDING;
        })
            .addCase(forms_1.fetchFormMapping.fulfilled, (state, action) => {
            if (!action.payload) {
                state.getFormExpandedStatus = constants_1.REQUEST_STATUS.FAILED;
                (0, react_toastify_1.toast)('Error occur while getting the form data', {
                    type: 'error',
                    autoClose: 5000,
                });
                return;
            }
            state.getFormExpandedStatus = constants_1.REQUEST_STATUS.SUCCEEDED;
            state.mappings = action.payload;
        })
            .addCase(forms_1.fetchFormMapping.rejected, (state, action) => {
            state.getFormExpandedStatus = constants_1.REQUEST_STATUS.FAILED;
            (0, react_toastify_1.toast)('Error occur while getting the form data', {
                type: 'error',
                autoClose: 5000,
            });
        });
        // Update question
        builder
            .addCase(forms_1.updateQuestion.pending, (state) => {
            state.getFormExpandedStatus = constants_1.REQUEST_STATUS.PENDING;
        })
            .addCase(forms_1.updateQuestion.fulfilled, (state, action) => {
            if (!action.payload) {
                state.getFormExpandedStatus = constants_1.REQUEST_STATUS.FAILED;
                (0, react_toastify_1.toast)('Error updating then question', {
                    type: 'error',
                    autoClose: 5000,
                });
                return;
            }
        })
            .addCase(forms_1.updateQuestion.rejected, (state, action) => {
            state.getFormExpandedStatus = constants_1.REQUEST_STATUS.FAILED;
            (0, react_toastify_1.toast)('Error updating then question', {
                type: 'error',
                autoClose: 5000,
            });
        });
        // Make Form Compliant (Fix form issues)
        builder
            .addCase(forms_1.makeFormCompliant.pending, (state) => {
            state.getFormExpandedStatus = constants_1.REQUEST_STATUS.PENDING;
        })
            .addCase(forms_1.makeFormCompliant.fulfilled, (state, action) => {
            if (!action.payload) {
                state.getFormExpandedStatus = constants_1.REQUEST_STATUS.FAILED;
                (0, react_toastify_1.toast)('Error occur while fixing the form', {
                    type: 'error',
                    autoClose: 5000,
                });
                return;
            }
        })
            .addCase(forms_1.makeFormCompliant.rejected, (state, action) => {
            state.getFormExpandedStatus = constants_1.REQUEST_STATUS.FAILED;
            (0, react_toastify_1.toast)('Error occur while fixing the form', {
                type: 'error',
                autoClose: 5000,
            });
        });
        // Set form type
        builder
            .addCase(forms_1.setFormType.pending, (state) => {
            state.getFormExpandedStatus = constants_1.REQUEST_STATUS.PENDING;
        })
            .addCase(forms_1.setFormType.fulfilled, (state, action) => {
            if (!action.payload) {
                state.getFormExpandedStatus = constants_1.REQUEST_STATUS.FAILED;
                (0, react_toastify_1.toast)('Error occur while setting the form type', {
                    type: 'error',
                    autoClose: 5000,
                });
                return;
            }
        })
            .addCase(forms_1.setFormType.rejected, (state, action) => {
            state.getFormExpandedStatus = constants_1.REQUEST_STATUS.FAILED;
            (0, react_toastify_1.toast)('Error occur while setting the form type', {
                type: 'error',
                autoClose: 5000,
            });
        });
        // Set form document type
        builder
            .addCase(forms_1.updateDocumentType.pending, (state) => {
            state.getFormExpandedStatus = constants_1.REQUEST_STATUS.PENDING;
        })
            .addCase(forms_1.updateDocumentType.fulfilled, (state, action) => {
            if (!action.payload) {
                state.getFormExpandedStatus = constants_1.REQUEST_STATUS.FAILED;
                (0, react_toastify_1.toast)('Error occur while setting the form type', {
                    type: 'error',
                    autoClose: 5000,
                });
                return;
            }
        })
            .addCase(forms_1.updateDocumentType.rejected, (state, action) => {
            state.getFormExpandedStatus = constants_1.REQUEST_STATUS.FAILED;
            (0, react_toastify_1.toast)('Error occur while setting the form type', {
                type: 'error',
                autoClose: 5000,
            });
        });
        // Fetch all form collection
        builder
            .addCase(formCollection_1.fetchAllFormCollections.pending, (state) => {
            state.formCollections = [];
            state.getFormCollectionStatus = constants_1.REQUEST_STATUS.PENDING;
        })
            .addCase(formCollection_1.fetchAllFormCollections.fulfilled, (state, action) => {
            if (action.payload) {
                state.formCollections = action.payload;
                state.getFormCollectionStatus = constants_1.REQUEST_STATUS.SUCCEEDED;
            }
            else {
                state.getFormCollectionStatus = constants_1.REQUEST_STATUS.FAILED;
                (0, react_toastify_1.toast)('Error loading the form Collection', {
                    type: 'error',
                    autoClose: 5000,
                });
            }
        })
            .addCase(formCollection_1.fetchAllFormCollections.rejected, (state, action) => {
            state.getFormCollectionStatus = constants_1.REQUEST_STATUS.FAILED;
            (0, react_toastify_1.toast)('Error loading the form Collection', {
                type: 'error',
                autoClose: 5000,
            });
        });
        // Insert form collection
        builder
            .addCase(formCollection_1.insertFormCollection.pending, (state) => {
            state.createFormCollectionStatus = constants_1.REQUEST_STATUS.PENDING;
        })
            .addCase(formCollection_1.insertFormCollection.fulfilled, (state, action) => {
            if (action.payload) {
                state.createFormCollectionStatus = constants_1.REQUEST_STATUS.SUCCEEDED;
                (0, react_toastify_1.toast)('Form collection created successfully.', {
                    type: 'success',
                    autoClose: 5000,
                });
            }
            else {
                state.createFormCollectionStatus = constants_1.REQUEST_STATUS.FAILED;
                (0, react_toastify_1.toast)('Error creating form collection', {
                    type: 'error',
                    autoClose: 5000,
                });
            }
        })
            .addCase(formCollection_1.insertFormCollection.rejected, (state, action) => {
            state.createFormCollectionStatus = constants_1.REQUEST_STATUS.FAILED;
            (0, react_toastify_1.toast)('Error creating form collection', {
                type: 'error',
                autoClose: 5000,
            });
        });
        // Delete form collection
        builder
            .addCase(formCollection_1.deleteFormCollection.pending, (state) => {
            state.createFormCollectionStatus = constants_1.REQUEST_STATUS.PENDING;
        })
            .addCase(formCollection_1.deleteFormCollection.fulfilled, (state, action) => {
            if (action.payload) {
                state.createFormCollectionStatus = constants_1.REQUEST_STATUS.SUCCEEDED;
                (0, react_toastify_1.toast)('Form Collection deleted successfully.', {
                    type: 'success',
                    autoClose: 5000,
                });
            }
            else {
                state.createFormCollectionStatus = constants_1.REQUEST_STATUS.FAILED;
                (0, react_toastify_1.toast)('Error deleting the form collection', {
                    type: 'error',
                    autoClose: 5000,
                });
            }
        })
            .addCase(formCollection_1.deleteFormCollection.rejected, (state, action) => {
            state.createFormCollectionStatus = constants_1.REQUEST_STATUS.FAILED;
            (0, react_toastify_1.toast)('Error deleting form collection', {
                type: 'error',
                autoClose: 5000,
            });
        });
        // Update form collection
        builder
            .addCase(formCollection_1.updateFormCollection.pending, (state) => {
            state.createFormCollectionStatus = constants_1.REQUEST_STATUS.PENDING;
        })
            .addCase(formCollection_1.updateFormCollection.fulfilled, (state, action) => {
            if (action.payload) {
                state.createFormCollectionStatus = constants_1.REQUEST_STATUS.SUCCEEDED;
                (0, react_toastify_1.toast)('Form collection updated successfully.', {
                    type: 'success',
                    autoClose: 5000,
                });
            }
            else {
                state.createFormCollectionStatus = constants_1.REQUEST_STATUS.FAILED;
                (0, react_toastify_1.toast)('Error updating form collection', {
                    type: 'error',
                    autoClose: 5000,
                });
            }
        })
            .addCase(formCollection_1.updateFormCollection.rejected, (state, action) => {
            state.createFormCollectionStatus = constants_1.REQUEST_STATUS.FAILED;
            (0, react_toastify_1.toast)('Error updating form collection', {
                type: 'error',
                autoClose: 5000,
            });
        });
        // Send form message as text
        builder
            .addCase(forms_1.sendFormWithText.pending, (state) => { })
            .addCase(forms_1.sendFormWithText.fulfilled, (state, action) => {
            if (!action.payload) {
                (0, react_toastify_1.toast)('An error occurs while sending the form to patient', {
                    type: 'error',
                    autoClose: 5000,
                });
                return;
            }
            (0, react_toastify_1.toast)('The form has been sent to the patient successfully.', {
                type: 'success',
                autoClose: 5000,
            });
        })
            .addCase(forms_1.sendFormWithText.rejected, (state, action) => {
            (0, react_toastify_1.toast)('An error occurs while sending the form to patient', {
                type: 'error',
                autoClose: 5000,
            });
        });
    },
});
// Action creators are generated for each case reducer function
_a = exports.formSlice.actions;
exports.default = exports.formSlice.reducer;
