"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TENANT_PROFILE_INITIAL_VALUES = exports.TENANT_INITIAL_VALUES = void 0;
exports.TENANT_INITIAL_VALUES = {
    tenantName: '',
    practiveName: '',
    primaryContactPhone: '',
    primaryContactEmail: '',
    voipProviderId: '',
    smsNumber: '',
    smsUser: '',
    officeExtensions: '',
    timeZone: '',
    isPaymentEnable: false,
    paymentPercentage: 2,
};
exports.TENANT_PROFILE_INITIAL_VALUES = {
    tenantName: '',
    practiveName: '',
    primaryContactPhone: '',
    primaryContactEmail: '',
    timeZone: '',
};
