"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = __importStar(require("react"));
const material_1 = require("@mui/material");
const icons_material_1 = require("@mui/icons-material");
const react_redux_1 = require("react-redux");
const typography_1 = require("@styles/typography");
const forms_1 = require("@services/forms");
const constants_1 = require("@appConstants/constants");
const SendFormButton = ({ isSelected, patientId, phoneNumber }) => {
    const dispatch = (0, react_redux_1.useDispatch)();
    const { availableForms, formCollections } = (0, react_redux_1.useSelector)((state) => state.formReducer);
    const { isKiosk } = (0, react_redux_1.useSelector)((state) => state.kioskReducer);
    const [anchorEl, setAnchorEl] = (0, react_1.useState)(null);
    const [openDialog, setOpenDialog] = (0, react_1.useState)(false);
    const [selectedForms, setSelectedForms] = (0, react_1.useState)([]);
    const [expanded, setExpanded] = (0, react_1.useState)('');
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const formClick = (event, form) => {
        event.stopPropagation();
        setSelectedForms([form]);
        setOpenDialog(true);
    };
    const handleChange = (event, id, isExpanded) => {
        event.stopPropagation();
        setExpanded(isExpanded ? id : '');
    };
    const collectionClick = (collection) => {
        const forms = [];
        collection.forms.forEach((id) => {
            const form = availableForms.find((f) => f.formId == id);
            if (form) {
                forms.push(form);
            }
        });
        setSelectedForms(forms);
        setOpenDialog(true);
    };
    const showCollectionForms = (collection) => {
        if (collection && collection.forms && Array.isArray(collection.forms)) {
            return ((0, jsx_runtime_1.jsx)(react_1.default.Fragment, { children: collection.forms.map((formId) => {
                    const form = availableForms.find((f) => f.formId == formId);
                    if (form) {
                        return (0, jsx_runtime_1.jsx)(material_1.MenuItem, { children: form.name }, form.formId);
                    }
                    return (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, {});
                }) }));
        }
        return (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, {});
    };
    const handleDialogClose = (results) => {
        setOpenDialog(false);
        if (!results) {
            return;
        }
        if (results.cancel) {
            return;
        }
        if (results.sms) {
            const bodyFormData = new FormData();
            selectedForms.forEach((form) => {
                bodyFormData.append('formUrls[]', form.url);
            });
            bodyFormData.append('PatientId', patientId.toString());
            bodyFormData.append('smsNumber', phoneNumber);
            dispatch((0, forms_1.sendFormWithText)(bodyFormData));
        }
        else if (results.kiosk) {
            const formUrls = [];
            selectedForms.forEach((form) => {
                formUrls.push(`${form.url}?connectuserid=${patientId}`);
            });
            if (results.kioskNumber) {
                dispatchedFormToKiosk(formUrls, 0, results.kioskNumber);
            }
        }
        handleClose();
    };
    const dispatchedFormToKiosk = (formUrls, index, kioskId) => {
        if (index >= formUrls.length) {
            return;
        }
        dispatch((0, forms_1.sendFormToKiosk)({ kioskId, formUrl: formUrls[index] }));
        setTimeout(() => {
            dispatchedFormToKiosk(formUrls, index + 1, kioskId);
        }, constants_1.ONE_SECOND);
    };
    if (!availableForms || availableForms.length < 1) {
        return (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, {});
    }
    return ((0, jsx_runtime_1.jsxs)(react_1.default.Fragment, { children: [(0, jsx_runtime_1.jsx)(material_1.Button, { id: 'basic-button', sx: { minWidth: '35px', maxWidth: '35px' }, "aria-controls": open ? 'basic-menu' : undefined, "aria-haspopup": 'true', "aria-expanded": open ? 'true' : undefined, onClick: handleClick, children: (0, jsx_runtime_1.jsx)(icons_material_1.DescriptionOutlined, { color: isSelected ? 'success' : 'primary', sx: { fontSize: '25px' } }) }), (0, jsx_runtime_1.jsxs)(material_1.Menu, { id: 'basic-menu', anchorEl: anchorEl, open: open, onClose: handleClose, MenuListProps: {
                    'aria-labelledby': 'basic-button',
                }, children: [(0, jsx_runtime_1.jsx)(typography_1.MainTypography, { sx: { fontWeight: 600, m: 1 }, children: "FORMS" }), availableForms.map((form) => ((0, jsx_runtime_1.jsx)(material_1.MenuItem, { onClick: (e) => formClick(e, form), children: form.name }, form.formId))), (formCollections === null || formCollections === void 0 ? void 0 : formCollections.length) > 0 && (0, jsx_runtime_1.jsx)(typography_1.MainTypography, { sx: { fontWeight: 600, m: 1 }, children: "FORM COLLECTIONS" }), formCollections === null || formCollections === void 0 ? void 0 : formCollections.map((collection) => ((0, jsx_runtime_1.jsxs)(material_1.Accordion, { TransitionProps: { unmountOnExit: true }, expanded: expanded === collection._id, onChange: (e, isExpanded) => handleChange(e, collection._id, isExpanded), children: [(0, jsx_runtime_1.jsxs)(material_1.AccordionSummary, { expandIcon: (0, jsx_runtime_1.jsx)(icons_material_1.ExpandMore, {}), children: [(0, jsx_runtime_1.jsx)(material_1.Typography, { children: collection.name }), (0, jsx_runtime_1.jsx)(material_1.Button, { variant: 'contained', color: 'primary', size: 'small', sx: { ml: 1, mr: 1 }, onClick: (event) => {
                                            event.stopPropagation();
                                            collectionClick(collection);
                                        }, children: "SEND" })] }), (0, jsx_runtime_1.jsx)(material_1.AccordionDetails, { children: showCollectionForms(collection) })] }, collection._id)))] }), (0, jsx_runtime_1.jsx)(ShowSendOptions, { isKiosk: isKiosk, openDialog: openDialog, patientId: patientId, phoneNumber: phoneNumber, onClose: handleDialogClose })] }));
};
const ShowSendOptions = ({ patientId, phoneNumber, openDialog, onClose }) => {
    const dispatch = (0, react_redux_1.useDispatch)();
    const { kioskNumbers } = (0, react_redux_1.useSelector)((state) => state.kioskReducer);
    (0, react_1.useEffect)(() => {
        if (openDialog) {
            dispatch((0, forms_1.getFormKioskNumbers)());
        }
    }, [openDialog]);
    const handleClose = (event) => {
        event.stopPropagation();
        onClose({ sms: false, kiosk: false, cancel: true });
    };
    const selectSms = (event) => {
        event.stopPropagation();
        onClose({ sms: true, kiosk: false, cancel: false });
    };
    const selectKiosk = (event, kioskNumber) => {
        event.stopPropagation();
        onClose({ sms: false, kiosk: true, cancel: false, kioskNumber });
    };
    return ((0, jsx_runtime_1.jsxs)(material_1.Dialog, { open: openDialog, onClose: handleClose, children: [(0, jsx_runtime_1.jsx)(material_1.DialogTitle, { children: "Choose, where to send form" }), (0, jsx_runtime_1.jsx)(material_1.DialogContent, { children: (0, jsx_runtime_1.jsxs)(material_1.List, { children: [patientId && phoneNumber && ((0, jsx_runtime_1.jsx)(material_1.ListItem, { children: (0, jsx_runtime_1.jsx)(material_1.Button, { fullWidth: true, variant: 'outlined', color: 'primary', onClick: selectSms, children: "TEXT" }) })), Array.isArray(kioskNumbers) &&
                            kioskNumbers.map((kiosk) => ((0, jsx_runtime_1.jsx)(material_1.ListItem, { children: (0, jsx_runtime_1.jsxs)(material_1.Button, { fullWidth: true, variant: 'outlined', color: 'secondary', onClick: (e) => selectKiosk(e, kiosk), children: ["KIOSK - ", kiosk] }) }, kiosk)))] }) }), (0, jsx_runtime_1.jsx)(material_1.DialogActions, { onClick: handleClose, children: (0, jsx_runtime_1.jsx)(material_1.Button, { children: "Cancel" }) })] }));
};
exports.default = SendFormButton;
