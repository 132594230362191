"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const auth0_react_1 = require("@auth0/auth0-react");
const material_1 = require("@mui/material");
const react_redux_1 = require("react-redux");
const tenant_1 = require("@components/tenant");
const kioskSlice_1 = require("@redux/reducers/kioskSlice");
require("./kiosk.scss");
const KioskHome = () => {
    const { logout } = (0, auth0_react_1.useAuth0)();
    const dispatch = (0, react_redux_1.useDispatch)();
    const { formsToFillOut, kioskNumber } = (0, react_redux_1.useSelector)((state) => state.kioskReducer);
    const [currentForm, setCurrentForm] = (0, react_1.useState)();
    (0, react_1.useEffect)(() => {
        if (formsToFillOut && formsToFillOut.length > 0) {
            setCurrentForm(formsToFillOut[0]);
        }
        else {
            setCurrentForm(undefined);
        }
    }, [formsToFillOut]);
    let buttons = null;
    const nextButton = () => {
        if (formsToFillOut && formsToFillOut.length > 0) {
            dispatch((0, kioskSlice_1.shiftFormsToFillOut)());
        }
    };
    if ((formsToFillOut === null || formsToFillOut === void 0 ? void 0 : formsToFillOut.length) && (formsToFillOut === null || formsToFillOut === void 0 ? void 0 : formsToFillOut.length) > 0) {
        if ((formsToFillOut === null || formsToFillOut === void 0 ? void 0 : formsToFillOut.length) < 2) {
            buttons = ((0, jsx_runtime_1.jsx)(material_1.Button, { variant: 'contained', color: 'primary', onClick: nextButton, children: "Done" }));
        }
        else {
            buttons = ((0, jsx_runtime_1.jsx)(material_1.Button, { variant: 'contained', color: 'primary', onClick: nextButton, children: "Next" }));
        }
    }
    const handleLogout = () => {
        localStorage.clear();
        logout({ logoutParams: { returnTo: window.location.origin } });
    };
    return ((0, jsx_runtime_1.jsxs)(material_1.Box, { className: 'kioskRoot', children: [(0, jsx_runtime_1.jsx)(material_1.Box, { className: 'kioskHeader', children: (0, jsx_runtime_1.jsx)(tenant_1.KioskPersistConnector, {}) }), (0, jsx_runtime_1.jsx)(material_1.Box, { className: 'logoutHeader', children: (0, jsx_runtime_1.jsx)(material_1.Button, { onClick: handleLogout, variant: 'contained', color: 'primary', children: "Exit Kiosk" }) }), (0, jsx_runtime_1.jsxs)(material_1.Box, { className: 'formContained', children: [buttons && ((0, jsx_runtime_1.jsxs)(material_1.Box, { className: 'buttons', children: [buttons, (0, jsx_runtime_1.jsxs)(material_1.Typography, { variant: 'h6', children: ["KIOSK - ", kioskNumber] })] })), (currentForm && (0, jsx_runtime_1.jsx)("iframe", { className: 'iframe', src: currentForm })) || ((0, jsx_runtime_1.jsxs)(material_1.Typography, { variant: 'h2', children: ["KIOSK - ", kioskNumber] }))] })] }));
};
exports.default = KioskHome;
